<template>
  <div
    ref="parent"
    class="modal-quiz white--bg relative"
  >
    <div
      class="modal-quiz__close d-flex align-center justify-center gray-light-3--bg modal-close"
      @click="emit('close')"
    >
      <img
        loading="lazy"
        src="@/assets/images/svg/close-thin.svg"
        class="d-flex"
        alt="close"
      >
    </div>

    <div class="modal-quiz__wrap d-flex flex-column align-center">
      <div class="modal-quiz__progress">
        <p class="modal-quiz__progress-title title-5 text-center">
          Автоматический подбор самого выгодного интернета
        </p>
        <div class="modal-quiz__progress-bar hidden relative">
          <div
            class="modal-quiz__progress-active"
            :style="`width: ${100 - currentStep * (100 / allSteps.length)}%;`"
          />
        </div>
        <p class="d-flex justify-between subhead-3">
          <span>Готово</span><span>{{ Math.round(currentStep * (100 / allSteps.length)) }}%</span>
        </p>
      </div>
      <div
        class="modal-quiz__body w-100 d-flex flex-column relative"
        :class="{ 'change-page': pageChanging }"
      >
        <div class="modal-quiz__quest w-100 d-flex flex-column">
          <div
            v-if="
              allSteps[currentStep].target === 'availableProviders'
                && availableProviders.length
            "
            class="modal-quiz__quest-available title-5"
          >
            <span class="additional-light--text">
              <span
                class="mask-icon mask-size-lg mask-ischecked"
              /></span>
            По вашему адресу {{ availableProviders.length }} провайдер{{
              counter(availableProviders)
            }}
            могут подключить тарифы, подходящие вам
          </div>
          <div
            v-if="badCheck && allSteps[currentStep].target === 'address'"
            class="modal-quiz__badcheck"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/cancel_red.svg"
              alt="cancel"
            >К сожалению, по вашему адресу не удалось определить техническую
            возможность подключения. Проверьте корректность введенного адреса.
            Попробуйте его изменить или
            <a
              class="secondary-dark--text"
              :href="`/${currentCity?.url}/providers-not-found/?houseUrl=${houseUrl}`"
              @click.prevent="goToPNF"
            >посмотрите провайдеров в городе</a>
          </div>
          <p class="modal-quiz__quest-title headline-3">
            {{
              allSteps[
                currentStep > allSteps.length ? allSteps.length : currentStep
              ].question.title
            }}
          </p>
          <p class="modal-quiz__quest-subtitle title-5">
            {{
              allSteps[
                currentStep > allSteps.length ? allSteps.length : currentStep
              ].question.subtitle
            }}
          </p>

          <template
            v-if="
              allSteps[
                currentStep > allSteps.length ? allSteps.length : currentStep
              ].target === 'availableProviders'
            "
          >
            <LazyVRadio
              v-model="setResult"
              class="modal-quiz__quest-radio justify-between title-5 modal-quiz__quest-item"
            >
              <LazyVRadioItem
                v-for="radio in allSteps[
                  currentStep > allSteps.length ? allSteps.length : currentStep
                ].question.response.filter((el) => !el.value)"
                :key="currentStep + radio.value"
                class="optimal"
                :value="radio.value"
              >
                {{ radio.name }}
              </LazyVRadioItem>
            </LazyVRadio>
            <div class="d-flex flex-wrap justify-between title-5">
              <LazyVCheckbox
                v-for="chbx in allSteps[
                  currentStep > allSteps.length ? allSteps.length : currentStep
                ].question.response.filter((el) => !!el.value)"
                :key="chbx.id"
                v-model="setAvailableProvidersChbx"
                class="modal-quiz__quest-item"
                :value="chbx.value"
                :class="chbx.class"
              >
                {{ chbx.name }}
              </LazyVCheckbox>
            </div>
          </template>
          <template
            v-else-if="
              allSteps[
                currentStep > allSteps.length ? allSteps.length : currentStep
              ].target === 'notAvailableProviders'
            "
          >
            <LazyVRadio
              v-model="setResult"
              class="modal-quiz__quest-radio justify-between title-5 modal-quiz__quest-item"
            >
              <LazyVRadioItem
                v-for="radio in allSteps[
                  currentStep > allSteps.length ? allSteps.length : currentStep
                ].question.response.filter((el) => !el.value)"
                :key="currentStep + radio.value"
                class="optimal"
                :value="radio.value"
              >
                {{ radio.name }}
              </LazyVRadioItem>
            </LazyVRadio>
            <div class="d-flex flex-wrap justify-between title-5">
              <LazyVCheckbox
                v-for="chbx in allSteps[
                  currentStep > allSteps.length ? allSteps.length : currentStep
                ].question.response.filter((el) => !!el.value)"
                :key="chbx.id"
                v-model="setNotAvailableProvidersChbx"
                class="modal-quiz__quest-item"
                :value="chbx.value"
                :class="chbx.class"
              >
                {{ chbx.name }}
              </LazyVCheckbox>
            </div>
          </template>
          <template
            v-else-if="
              allSteps[
                currentStep > allSteps.length ? allSteps.length : currentStep
              ].question.response
            "
          >
            <LazyVRadio

              v-model="setResult"
              class="modal-quiz__quest-radio"
            >
              <div class="d-flex flex-wrap justify-between title-5 modal-quiz__quest-item">
                <LazyVRadioItem
                  v-for="radio in allSteps[
                    currentStep > allSteps.length ? allSteps.length : currentStep
                  ].question.response"
                  :key="currentStep + radio.value"
                  :class="radio.class"
                  :value="radio.value"
                >
                  <span
                    v-if="
                      allSteps[
                        currentStep > allSteps.length ? allSteps.length : currentStep
                      ].target === 'popularSortParams'
                    "
                    class="d-block"
                  >
                    <span class="d-block title-4">{{ radio.name }}</span>
                    <span class="d-block subhead-3 gray-b--text">{{
                      radio.subtitle
                    }}</span>
                    <span
                      v-if="radio.speed && radio.price"
                      class="d-flex justify-between align-center title-5 flex-wrap"
                      style="margin-top: 4px"
                    >
                      <span
                        v-if="radio.price && radio.price.min !== radio.price.max"
                        class="white--bg text-no-wrap"
                      >{{ radio.price.min }} - {{ radio.price.max }} ₽/мес</span>
                      <span
                        v-else-if="radio.price && radio.price.min"
                        class="white--bg text-no-wrap"
                      >{{ radio.price.min }} ₽/мес</span>

                      <span
                        v-if="
                          radio.speed
                            && radio.speed.min !== radio.speed.max
                            && radio.value !== 'PRICE'
                        "
                        class="text-no-wrap"
                        style="margin-left: auto"
                      >{{ radio.speed.min }}+ Мбит/с</span>
                      <span
                        v-else-if="
                          radio.speed && radio.speed.min && radio.value !== 'PRICE'
                        "
                        class="text-no-wrap"
                        style="margin-left: auto"
                      >{{ radio.speed.min }} Мбит/с</span>
                    </span>
                  </span>
                  <template v-else>
                    {{ radio.name }}
                  </template>
                </LazyVRadioItem>
              </div>
            </LazyVRadio>
          </template>
          <div
            v-else
            class="modal-quiz__address d-flex flex-column relative"
          >
            <LazyAddressCheckSearchAddressCheck
              v-if="disabledPortal"
              ref="searchAddressRef"
              :w-city="true"
            />
            <div
              v-else
              class="modal-quiz__as d-flex align-center text-no-wrap title-5"
              @click="openDialogAddress"
            >
              <img
                loading="lazy"
                src="@/assets/images/svg/pin-thin_sec.svg"
                class=""
                alt="pin"
                style="width: 24px; height: 24px;"
              >
              {{ getAddressText.fullAddress }}
            </div>
            <button
              v-if="configShowMap"
              class="modal-quiz__showMap title-5 secondary-dark--text d-flex align-center"
              @click="showMap"
            >
              <span class="modal-quiz__showMap-pin" />
              Выбрать на карте
            </button>
            <div class="modal-quiz__streets gray-p--bg d-flex flex-column">
              <p class="title-5 gray-dark--text">
                Или выберите из списка
              </p>
              <div class="modal-quiz__streets-wrap">
                <div
                  v-if="loadingStreets"
                  class="d-flex flex-wrap"
                >
                  <div
                    v-for="item in 5"
                    :key="item + 'streetSkeleton'"
                    class="d-flex am-n__streets-btn"
                    style="width: 120px; height: 25px; padding: 0"
                  >
                    <VSkeleton
                      class=""
                      style="width: 120px; min-height: 25px; max-height: 25px"
                    />
                  </div>
                </div>
                <template v-else>
                  <button
                    v-for="street in popularStreets"
                    :key="street.fias_id"
                    class="modal-quiz__streets-btn white--bg"
                    @click="selectStreet(street)"
                  >
                    {{ itemTitle(street) }}
                  </button>
                </template>
              </div>
              <button
                class="modal-quiz__streets-more black--text d-flex align-center"
                @click="showModalStreets = true"
              >
                Показать все улицы
                <img
                  style="width: 24px; height: 24px; margin-bottom: -3px; margin-left: 4px"
                  loading="lazy"
                  src="@/assets/images/svg/arrow-expand.svg"
                  alt="arrow"
                >
              </button>
            </div>
          </div>
          <div
            class="modal-quiz__quest-action d-flex justify-end"
            :class="{ active: showBar }"
          >
            <d-btn
              v-if="currentStep"
              color="custom"
              class="fwm modal-quiz__quest-back"
              icon
              @click="changePage(currentStep - 1)"
            >
              <img
                loading="lazy"
                src="@/assets/images/svg/arrow-right.svg"
                alt="arrow"
                style="transform: rotate(180deg); opacity: .7;"
              >
            </d-btn>
            <d-btn
              v-if="allSteps[currentStep].target !== 'address' || houseUrl"
              color="primary"
              class="fwm modal-quiz__quest-next title-4"
              :disabled="
                !(
                  result[allSteps[currentStep].target]
                  || result[allSteps[currentStep].target] === null
                  || result[allSteps[currentStep].target] === false
                  || (!allSteps[currentStep].question.response && houseUrl)
                  || (allSteps[currentStep].target === 'availableProviders'
                    && availableProvidersChbx.length)
                )
                  || (badCheck && allSteps[currentStep].target === 'address')
              "
              @click="changePage(currentStep + 1)"
            >
              {{ "Далее" }}
            </d-btn>
          </div>
        </div>
        <p class="modal-quiz__footer subhead-3 gray-b--text">
          Пройдите все шаги и получите оптимальный тарифный план
        </p>
      </div>
    </div>
    <LazyVDialog
      v-model="showDialogCity"
      :fullscreen="!disabledPortal"
      :width="disabledPortal ? 'auto' : ''"
    >
      <LazyModalCityCheck
        @close="showDialogCity = false"
        @changed-city="showDialogCity = false"
      />
    </LazyVDialog>
    <LazyVDialog
      v-model="showModalStreets"
      :fullscreen="!disabledPortal"
      :width="disabledPortal ? 'auto' : ''"
    >
      <LazyModalStreetsSelect
        @close="showModalStreets = false"
        @select-street="selectStreet"
      />
    </LazyVDialog>
    <LazyVDialog
      v-model="showDialogAddress"
      :fullscreen="!disabledPortal"
      :width="disabledPortal ? 'auto' : ''"
    >
      <ModalAddressCheckAdditional
        ref="modalAddressCheckRef"
        :redirect="false"
        title=""
        :new-window="false"
        :wo-focus="woFocus"
        :w-focus="!woFocus"
        @close="showDialogAddress = false"
        @close-all="showDialogAddress = false"
      />
    </LazyVDialog>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { counter } from '~/helpers'
import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import type { BaseAddressObject } from '~/api'
import { scrollToBlock } from '~/composible/scrollToBlock'
import { providersComposible } from '~/composible/getProviders'

const emit = defineEmits(['close'])

const ctx = useNuxtApp()
const mainStore = useMainStore()
const cityStore = useCities()
const tariffsStore = useTariffs()
const parent = ref()
const $route = useRoute()
const $router = useRouter()
const currentCity = computed(() => cityStore.getCity)
const houseUrl = computed(() => cityStore.getHouseUrl)
const houseHash = computed(() => cityStore.getHouseHash)
const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
const configShowMap = computed(() => mainStore.getConfigShowMap)
const disabledPortal = computed(() => mainStore.disabledPortal)
const $gtm = useGtm()
const result = ref({})
const currentStep = ref(0)
const showDialogCity = ref(false)
const woFocus = ref(false)
const hideProviders = ref(false)
const pageChanging = ref(false)
const showBar = ref(false)
const badCheck = ref(false)
const btnLoading = ref(false)
const sortStepShow = ref(true)
const showModalStreets = ref(false)
const showDialogAddress = ref(false)
const loadingStreets = ref(false)
const searchAddressRef = ref()
const modalAddressCheckRef = ref()
const quizResult = useCookie('quizResult')
const getAddressText = computed(() => cityStore.getAddressText)
const popularStreets = ref()

const fakeFn = () => {
  console.log(counter)
}

const showMap = () => {
  ctx.$event('mapDialog', { label: 'quiz', redirect: false })
}
const openDialogAddress = () => {
  woFocus.value = false
  showDialogAddress.value = true
}
const selectStreet = async (street) => {
  if (houseUrl.value) {
    let res
    try {
      res = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${currentCity.value?.fias_id}/ancestors/`, {
        method: 'GET',
        headers: {
          ...getCommonHeaders.value,
        },
        params: {
          include_self: true,
          morph: true,
        },
      })
    }
    catch {}
    cityStore.$patch({
      addressAncestors: res,
    })
    cityStore.house = {}
    tariffsStore.passThroughProviderId = null

    const coockieAddress = useCookie('currentAddress')
    coockieAddress.value = ''
  }
  woFocus.value = true
  nextTick(() => {
    if (searchAddressRef.value) searchAddressRef.value.chooseAddress(street)
    showDialogAddress.value = true
    nextTick(() => {
      if (modalAddressCheckRef.value)
        modalAddressCheckRef.value.chooseAddress(street)
      showModalStreets.value = false
    })
  })
}

const itemTitle = (street?: BaseAddressObject) => {
  return street ? `${street.short_name} ${street.name}` : ''
}

const client = ctx._apolloClients.default

const setResult = computed({
  get: () => result.value[allSteps.value[currentStep.value].target],
  set: (val) => {
    result.value[allSteps.value[currentStep.value].target] = val
    if (allSteps.value[currentStep.value].target === 'availableProviders') {
      availableProvidersChbx.value = []
    }
    changePage(currentStep.value + 1)
  },
})
const changePage = async (val) => {
  pageChanging.value = true
  if (
    allSteps.value[currentStep.value].target === 'popularSortParams'
    && allSteps.value[currentStep.value].question.response
    && result.value.popularSortParams
  ) {
    hideProviders.value
      = allSteps.value[currentStep.value]?.question?.response?.find(
        el => el.value === result.value?.popularSortParams,
      )?.providersIds
      && allSteps.value[currentStep.value]?.question?.response?.find(
        el => el.value === result.value?.popularSortParams,
      ).providersIds.length < 2
  }
  if (allSteps.value[currentStep.value].target === 'address') {
    getSortData()
    availableProviders.value = await fetchAvailableProviders()
  }
  if (
    allSteps.value[currentStep.value].target === 'availableProviders'
    && availableProvidersChbx.value.length
  )
    result.value[allSteps.value[currentStep.value].target]
      = availableProvidersChbx.value
  if (val > currentStep.value)
    sendGtm(
      allSteps.value[currentStep.value].target,
      allSteps.value[currentStep.value].target === 'address'
        ? getAddressText.value.houseAddress
        : result.value[allSteps.value[currentStep.value].target],
    )
  if (
    currentStep.value === allSteps.value.length - 1
    && val > currentStep.value
  ) {
    if (
      result.value.anotherProvider
      && result.value.anotherService
      && result.value.serviceSets
      && result.value.serviceSets.every(el =>
        result.value.anotherService.includes(el),
      )
      && allSteps.value?.find(el => el.target === 'anotherProvider')?.question
        ?.response?.length < 4
    ) {
      result.value.blocked = true
    }
    else {
      result.value.blocked = undefined
    }
    tariffsStore.$patch({
      quizTariffsInput: result.value,
    })
    quizResult.value = result.value
    const arr = [] as string[]
    Object.entries(result.value).forEach((el) => {
      if (el[1]) arr.push(`${el[0]}=${el[1]}`)
    })
    window.open(`/personal/quiz-results/?${arr.join('&')}`, '_blank')
    emit('close')
    nextTick(() => {
      if ($route.path === '/')
        $router.push(`/personal/address/${houseUrl.value}/`)
      else {
        const element = document.getElementsByClassName('layout')[0]
        scrollToBlock(element, 500)
      }
    })

    return
  }
  setTimeout(() => {
    currentStep.value = val
    pageChanging.value = false
  }, 120)
}
const availableProviders = ref()
const availableProvidersChbx = ref([])
const setAvailableProvidersChbx = computed({
  get: () => availableProvidersChbx.value,
  set: async (val) => {
    availableProvidersChbx.value = val
    result.value.availableProviders = undefined
  },
})
const setNotAvailableProvidersChbx = computed({
  get: () => result.value.notAvailableProviders,
  set: async (val) => {
    result.value.notAvailableProviders = val
  },
})
const steps = ref([
  {
    question: {
      title: 'Какими услугами будете пользоваться?',
      subtitle: 'Покажем оптимальные тарифы без лишних услуг',
      response: [
        {
          name: 'Интернет только',
          value: ['1'],
        },
        {
          name: 'Интернет + ТВ',
          value: ['1', '2'],
        },
        {
          name: 'Интернет + ТВ + Мобильная связь',
          value: ['1', '2', '4'],
        },
      ],
    },
    target: 'serviceSets',
  },
  {
    question: {
      title: 'У вас есть Wi-Fi роутер\n' + 'или необходимо его предоставить?',
      subtitle: 'Если необходимо, добавим к тарифу роутер по оптимальной цене',
      response: [
        {
          name: 'Необходим Wi-Fi роутер',
          value: true,
        },
        {
          name: 'У меня есть свой роутер',
          value: false,
        },
        {
          name: 'Не знаю',
          value: null,
        },
      ],
    },
    target: 'router',
  },
  {
    question: {
      title: 'Вам необходима ТВ-приставка?',
      subtitle:
        'Для подключения ТВ-каналов потребуется ТВ-приставка или приложение в вашем телевизоре. Если планируете подключение нескольких телевизоров, то и приставки/приложения нужны для каждого из них',
      response: [
        {
          name: 'Да, нужна одна ТВ-приставка',
          value: 1,
        },
        {
          name: 'Да, нужны 2 ТВ-приставки',
          value: 2,
        },
        {
          name: 'Да, нужны 3 ТВ-приставки',
          value: 3,
        },
        {
          name: 'Приставки не нужны',
          value: null,
        },
      ],
    },
    target: 'tvBox',
  },
  {
    question: {
      title: 'Укажите адрес,\nпо которому планируется подключение',
      subtitle:
        'Проведем поиск провайдеров, доступных именно по вашему адресу. Квартиру указывать не нужно',
      response: undefined,
    },
    target: 'address',
  },
  {
    question: {
      title: `Вы уже пользуетесь услугами какого-либо провайдера по указанному адресу?`,
      subtitle: 'Уточняем информацию, чтобы улучшить парамерты отбора',
      response: [
        {
          name: 'Никаким не пользуюсь',
          value: null,
        },
        {
          name: 'Другой',
          value: false,
        },
      ],
    },
    target: 'anotherProvider',
  },
  {
    question: {
      title: `Какими услугами провайдера пользуетесь сейчас?`,
      subtitle: 'Посмотрим как оптимизировать ваш тариф',
      response: [
        {
          name: 'Интернет',
          value: ['1'],
        },
        {
          name: 'Интернет и ТВ',
          value: ['1', '2'],
        },
        {
          name: 'Интернет, ТВ и моб. связь',
          value: ['1', '2', '4'],
        },
      ],
    },
    target: 'anotherService',
  },
  {
    question: {
      title: 'Что для вас наиболее важно?',
      subtitle: 'Отберем и покажем тарифы с необходимыми параметрами',
      response: [
        {
          name: 'Небольшая абонентская плата',
          subtitle: 'Самые дешевые тарифы. Среднее качество услуг.',
          value: 'PRICE',
        },
        {
          name: 'Оптимальная скорость по лучшей цене',
          subtitle:
            'Для работы и развлечений для всей семьи до 10 устройств. Надежные провайдеры с качественными услугами и поддержкой',
          value: 'BALANCE',
          class: 'optimal',
        },
        {
          name: 'Максимальная скорость',
          subtitle: 'Для требовательных онлайн игр и скачивания больших файлов',
          value: 'SPEED',
        },
      ],
    },
    target: 'popularSortParams',
  },
  {
    question: {
      title: 'Какому провайдеру отдаете предпочтение?',
      subtitle:
        'Определите приоритет сами или доверьте его нам. Мы покажем лучший тариф по вашим параметрам из всех провайдеров по вашему адресу',
      response: [
        {
          name: 'Подойдет любой',
          value: null,
        },
      ],
    },
    target: 'availableProviders',
  },
  {
    question: {
      title: 'Какого провайдера вы точно\n' + 'НЕ хотели бы подключать?',
      subtitle: 'Покажем Вам то, что необходимо',
      response: [
        {
          name: 'Подойдет любой',
          value: null,
        },
      ],
    },
    target: 'notAvailableProviders',
  },
])

const goToPNF = () => {
  emit('close')
  $router.push({
    path: `/${currentCity.value?.url}/providers-not-found/`,
    query: { houseUrl: houseUrl.value },
  })
}

const allSteps = computed(() => {
  return steps.value.filter((el) => {
    if (
      el.target === 'tvBox'
      && !(
        result.value
        && result.value['serviceSets']
        && result.value['serviceSets'].includes('2')
      )
    )
      return false
    if (
      el.target === 'anotherService'
      && !(result.value && result.value.anotherProvider)
    )
      return false
    const anotherProvider = steps.value.find(
      el => el.target === 'anotherProvider',
    )
    if (
      el.target === 'availableProviders'
      && (!(anotherProvider?.question?.response?.length > 3)
        || hideProviders.value)
    )
      return false
    if (
      el.target === 'notAvailableProviders'
      && (!(anotherProvider?.question?.response?.length > 4)
        || result.value.availableProviders
        || hideProviders.value)
    )
      return false
    if (el.target === 'popularSortParams' && !sortStepShow.value) return false
    return true
  })
})

const sendGtm = (quizStep, quizAnswers) => {
  if (quizStep === 'serviceSets')
    quizAnswers = quizAnswers
      .map(el =>
        el === '1'
          ? 'internet'
          : el === '2'
            ? 'tv'
            : el === '4'
              ? 'mobile'
              : '',
      )
      .join('_')
  if ($gtm) {
    $gtm.trackEvent({
      category: 'quiz',
      event: 'quizEvent',
      action: 'stepAnswered',
      quizStep,
      quizAnswers,
    })
  }
}
const { fetchProvidersList } = providersComposible()

const fetchAvailableProviders = async () => {
  const res = await fetchProvidersList({
    strictTechs: !!houseUrl.value,
  }, {
    tariffless: false,
    byTariffs: {
      serviceSets:
        result.value.serviceSets && result.value.serviceSets.length
          ? [result.value.serviceSets]
          : undefined,
    } })

  if (res && res.length) {
    const available = steps.value.find(
      el => el.target === 'availableProviders',
    )
    const notAvailable = steps.value.find(
      el => el.target === 'notAvailableProviders',
    )
    const providers = res.map((el) => {
      return {
        name: el.provider.name,
        value: el.provider.id,
        class: 'half',
      }
    })
    notAvailable.question.response = [
      ...providers,
      {
        name: 'Подойдет любой',
        value: null,
        class: 'half',
      },
    ]
    available.question.response = [
      ...providers,
      {
        name: 'Подойдет любой',
        value: null,
        class: 'half',
      },
    ]
  }

  return res
}
const checkAgg = async () => {
  const res = (
    await client.query({
      query: gql`
        query getQuizAgg(
          $input: LocationInfoInput!
          $tariffsInput2: BaseTariffsInput
        ) {
          location {
            locationTerms(input: $input) {
              tariffs(input: $tariffsInput2) {
                agg {
                  availableProviders {
                    id
                    logo
                    name
                    slug
                    logoT {
                      url(options: { width: 80, halign: "left" })
                      unoptimized
                    }
                  }
                  availableServiceGroups {
                    serviceIds
                    name
                    id
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        input: {
          hash: houseUrl.value
            ? houseHash.value || undefined
            : undefined,
          url: houseUrl.value ? houseUrl.value : undefined,
          addrObjFiasId: currentCity.value?.fias_id,
          strictTechs: !!houseUrl.value,
        },
        tariffsInput2: {
          sort: 'POPULAR',
          pageSize: 0,
          page: 1,
          serviceSets:
            result.value.serviceSets && result.value.serviceSets.length
              ? [result.value.serviceSets]
              : undefined,
        },
      },
      context: {
        headers: getCommonHeaders.value,
      },
      fetchPolicy: 'no-cache',
    })
  ).data.location.locationTerms.tariffs

  const service = steps.value.find(el => el.target === 'serviceSets')
  service.question.response = res?.agg?.availableServiceGroups
    .filter(el => el.id === '1' || el.id === '11' || el.id === '13')
    .map((el) => {
      return { ...el, value: el.serviceIds }
    })
  const providers = steps.value.find(el => el.target === 'anotherProvider')
  providers.question.response = [
    {
      name: 'Никаким не пользуюсь',
      value: null,
    },
    ...res?.agg?.availableProviders.map((el) => {
      return {
        ...el,
        value: el.id,
        class: res?.agg?.availableProviders.length < 3 ? '' : 'half',
      }
    }),
    {
      name: 'Другой',
      value: false,
      class: res?.agg?.availableProviders.length < 3 ? '' : 'half',
    },
  ]
}
const getSortData = async () => {
  const res = (
    await client.query({
      query: gql`
  query getQuizAgg(
    $input: LocationInfoInput!
    $tariffsInput2: BaseTariffsInput
  ) {
    location {
      locationTerms(input: $input) {
        tariffs(input: $tariffsInput2) {
          agg {
            popularImportanceAgg {
              importance
              providerIds
              price {
                max
                min
              }
              speed {
                max
                min
              }
            }
          }
        }
      }
    }
  }
`,
      variables: {
        input: {
          hash: houseUrl.value
            ? houseHash.value || undefined
            : undefined,
          url: houseUrl.value ? houseUrl.value : undefined,
          addrObjFiasId: currentCity.value?.fias_id,
          strictTechs: !!houseUrl.value,
        },
        tariffsInput2: {
          sort: 'POPULAR',
          pageSize: 1,
          page: 1,
          serviceSets:
            result.value.serviceSets && result.value.serviceSets.length
              ? [result.value.serviceSets]
              : undefined,
        },
      },
      context: {
        headers: getCommonHeaders.value,
      },
      fetchPolicy: 'no-cache',
    })
  ).data.location.locationTerms.tariffs.agg.popularImportanceAgg

  const sortParams = steps.value.find(el => el.target === 'popularSortParams')
    .question.response

  if (res && res.length) {
    res.forEach((el) => {
      const target = sortParams.find(e => e.value === el.importance)
      target.price = el.price
      target.speed = el.speed
      target.providersIds = el.providerIds
    })
  }
  else {
    sortParams.forEach((el) => {
      el.price = undefined
      el.speed = undefined
      el.providerIds = undefined
    })
  }
  sortStepShow.value = res.some(el => el.price || el.speed)
}
const setWrongAddress = () => {
  ctx.$event('gtmSendEvent', {
    event: 'trackEvent',
    action: 'address-form',
    label: 'fail',
  })
  // changePage(0);
  badCheck.value = true
}

const houseChanged = async (next: boolean) => {
  if ($route.name === 'personal-address-slug')
    $router.push({ path: `/personal/address/${houseUrl.value}/` })
  await checkAgg()
  const service = steps.value.find(el => el.target === 'serviceSets')
  if (service?.question?.response?.length) {
    if (next) changePage(currentStep.value + 1)
    badCheck.value = false
  }
  else {
    setWrongAddress()
    if (searchAddressRef.value) searchAddressRef.value.setFocused(false)
  }

  btnLoading.value = false
}

const getPopularStreets = async () => {
  loadingStreets.value = true
  try {
    popularStreets.value = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/children/`, {
      method: 'GET',
      headers: {
        ...getCommonHeaders.value,
      },
      params: {
        fias_id: currentCity.value?.fias_id,
        city_type: 'all',
        sort: 'POPULATION',
        level: 'street',
        limit: 10,
        with_available_smart_filters: false,
        with_provider_slugs: false,
        optimized: true,
        only_with_houses: true,
      },
    })
  }
  catch (e) {
    console.log(e)
  }
  loadingStreets.value = false
}

watch(
  () => houseUrl.value,
  () => {
    if (houseUrl.value) {
      houseChanged(true)
    }
  },
)

watch(
  () => currentCity.value?.fias_id,
  () => {
    getPopularStreets()
  },
)
const scrollTopFn = () => {
  parent.value.scrollTo(0, 0)
  window.removeEventListener('resize', scrollTopFn)
}
onBeforeMount(async () => {
  if ($gtm) {
    $gtm.trackEvent({
      category: 'quiz',
      event: 'quizEvent',
      action: 'quizOpen',
    })
  }
  if (houseUrl.value) houseChanged(false)
  else await checkAgg()
  if (currentCity.value?.fias_id) getPopularStreets()
})
onMounted(() => {
  nextTick(() => parent.value.scrollTo(0, 0))
  if (ctx.$device.isAndroid) {
    window.addEventListener('resize', scrollTopFn)
  }
  preloadComponents([
    'ModalAddressCheckAdditional',
  ])
})

onBeforeUnmount(async () => {
  if (ctx.$device.isAndroid) {
    window.removeEventListener('resize', scrollTopFn)
  }
})
</script>

<style lang="scss" scoped>
.modal-quiz {
  max-width: 100%;
  width: 600px;
  padding: 40px 40px 16px;
  border-radius: 16px;
  @media (max-width: getBreakpoint(tablet)) {
    border-radius: 0;
    height: 100%;
    padding: 24px 16px 0;
  }
  .mask-ischecked {
    mask-image: url('@/assets/images/svg/ischecked.svg');
  }
  &__close {
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 1;
  }

  &:deep(.q-result__blocked) {
    margin-top: 40px;
  }
  &:deep(.q-result__body) {
    padding: 0;
    box-shadow: none;
    border: none;
    min-height: 556px;
    @media (max-width: getBreakpoint(tablet)) {
      min-height: unset;
    }
  }

  &__wrap {
    @media (max-width: getBreakpoint(tablet)) {
      height: 100%;
    }
    //min-height: 550px;
    //@media (max-width: getBreakpoint(tablet)) {
    //  min-height: unset;
    //}
  }

  &__badcheck {
    width: 100%;
    padding: 12px 12px 12px 40px;
    background: rgba(color(red), 0.1);
    margin-bottom: 24px;
    position: relative;
    img {
      position: absolute;
      left: 12px;
      top: 12px;
      width: 24px;
      height: 24px;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      background: color(red);
    }
    .secondary-dark--text {
      transition: color 0.24s;
      &:hover {
        color: color(primary);
      }
    }
  }

  &__progress {
    width: 100%;
    max-width: 700px;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 32px;
      //padding-right: 24px;
      max-width: unset;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-bottom: 32px;
    }
    //@media (max-width: getBreakpoint(mobile-md)) {
    //  padding-right: 40px;
    //}

    &-title {
      margin-bottom: 16px;
      @media (max-width: getBreakpoint(tablet)) {
        max-width: calc(100% - 40px);
      }
    }
    &-bar {
      display: flex;
      height: 9px;
      border-radius: 10px;
      margin-bottom: 4px;
      background: linear-gradient(
        90deg,
        #ee3c6b 5.03%,
        #a949e7 42.39%,
        #5f60f5 67.38%,
        #5b73f1 76.39%,
        #5bbbe3 99.87%
      );
      @media (max-width: getBreakpoint(mobile-lg)) {
        height: 8px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 7px;
      }
    }
    &-active {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      max-width: calc(100% - 4px);
      background-image: radial-gradient(
        circle at 0% 50%,
        rgba(204, 0, 0, 0) 5px,
        color(gray-darker) 5px
      );
      border-radius: 10px;
      transition: width 0.24s;
      @media (max-width: getBreakpoint(mobile-lg)) {
        background-image: radial-gradient(
          circle at 0% 50%,
          rgba(204, 0, 0, 0) 4px,
          color(gray-darker) 4px
        );
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        background-image: radial-gradient(
          circle at 0% 50%,
          rgba(204, 0, 0, 0) 3px,
          color(gray-darker) 4px
        );
        max-width: calc(100% - 3px);
      }
    }
    &-step {
      flex: 1 1 40px;
      margin-right: 16px;
      border-radius: 50px;
    }
  }

  &__body {
    &.change-page {
      .modal-quiz__quest-title,
      .modal-quiz__quest-subtitle,
      .modal-quiz__quest-radio {
        opacity: 0;
      }
      .modal-quiz__quest-action {
        opacity: 0;
        @media (max-width: getBreakpoint(tablet)) {
          opacity: 1;
        }
      }
    }

    @media (max-width: getBreakpoint(desktop)) {
      flex-direction: column;
    }
    @media (max-width: getBreakpoint(tablet)) {
      flex-grow: 1;
      //padding-bottom: 64px;
    }
  }

  &__quest {
    @media (max-width: getBreakpoint(desktop)) {
      max-width: unset;
      flex: unset;
    }

    &-title {
      margin-bottom: 8px;
      transition: opacity 0.12s;
      white-space: pre-wrap;
      @media (max-width: getBreakpoint(tablet)) {
        white-space: unset;
        margin-bottom: 4px;
      }
    }

    &-subtitle {
      margin-bottom: 24px;
      transition: opacity 0.12s;

      //@media (max-width: getBreakpoint(desktop)) {
      //  margin-bottom: 16px;
      //}
    }

    &-available {
      background: rgba(color(additional-light), 0.1);
      position: relative;
      padding: 12px 12px 12px 40px;
      margin-bottom: 24px;
      .mask-icon {
        position: absolute;
        top: 12px;
        left: 12px;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        background-color: color(additional-light);
      }
    }

    &-radio {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: unset;
      margin-bottom: 16px;
      transition: opacity 0.12s;
    }
    &-item {
      margin-right: 0px;
      margin-bottom: 12px;
      &:deep(.v-selection-control__wrapper) {
        background: white;
      }
      &:deep(.v-selection-control) {
        flex: 0 0 100%;
        padding: 16px !important;
        border-radius: 8px !important;
        background: color(gray-p);
        border: 1px solid color(gray-darker);
        align-items: flex-start;
        color: color(black);
        position: relative;
        @media (max-width: getBreakpoint(mobile-lg)) {
          padding: 14px 16px !important;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          padding: 10px 12px !important;
        }
      }
      &:deep(.v-label) {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
        }
      }
      &.half, .half {
        flex: 0 0 calc(50% - 6px);
      }
      .optimal {
        background: color(blue);
      }
      &:deep(.v-selection-control--dirty) {
        border-color: color(primary);
      }
    }

    &-action {
      transition: opacity 0.12s;
      margin-bottom: 40px;
      //@media (max-width: getBreakpoint(tablet)) {
      //  margin-bottom: 0;
      //  transition: bottom .24s;
      //  position: fixed;
      //  bottom: 0;
      //  left: 0;
      //  right: 0;
      //  z-index: 100;
      //  background-color: color(white);
      //  box-shadow: 0px 0px 10px rgba(2, 4, 30, 0.1);
      //  padding: 12px;
      //&.active {
      //  bottom: 0;
      //}
      //}
    }
    &-back {
      width: 64px;
      height: 64px;
      border: 1px solid color(gray-dark);
      margin-right: 16px;
      @media (max-width: getBreakpoint(tablet)) {
        width: 44px;
        height: 44px;
        margin-right: 12px;
      }
    }
    &-next {
      padding: 16px 40px;
      border-radius: 36px;

      &:deep(.d-spinner:after) {
        width: 26px;
        height: 26px;
        border-color: color(white) color(white) color(white) transparent;
        @media (max-width: getBreakpoint(tablet)) {
          width: 20px;
          height: 20px;
        }
      }
      @media (max-width: getBreakpoint(tablet)) {
        margin-left: auto;
        flex: 1 1 80%;
        height: 44px;
      }
    }
  }

  &__address {
    margin-bottom: 22px;
    &:deep(.sa-check__check-textarea),
    &:deep(.sa-check__check),
    &:deep(.sa-check__loading) {
      background: color(gray-p);
    }
    &:deep(.sa-check__check-popper) {
      background: color(gray-p);
      &.show {
        max-height: 220px;
        min-height: 220px;
      }
    }
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 8px;
    }
  }

  &__as {
    background: color(gray-p);
    height: 56px;
    width: 100%;
    border-radius: 50px;
    padding: 16px 24px 16px 12px;
    border: 1px solid color(gray-2);
    overflow: hidden;
    @media (max-width: getBreakpoint(tablet)) {
      height: 44px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 42px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 40px;
    }
    img {
      margin-right: 4px;
    }
  }

  &__showMap {
    margin-left: auto;
    margin-top: 12px;
    transition: color 0.24s;
    &:hover {
      color: color(primary);
    }
    &-pin {
      margin-right: 4px;
      width: 24px;
      height: 24px;
      mask-image: url('@/assets/images/svg/location.svg');
      background-color: currentColor;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: 24px;
    }
  }
  &__streets {
    padding: 16px 8px 16px 16px;
    border-radius: 12px;
    margin-top: 24px;

    &-wrap {
      margin-top: 12px;
      max-height: 122px;
      overflow: hidden;
      margin-bottom: 16px;
    }
    &-btn {
      margin-right: 8px;
      margin-bottom: 8px;
      padding: 8px;
      border-radius: 4px;
    }
    &-more {
      margin-left: auto;
      transition: color 0.24s;
      &:hover {
        color: color(primary);
      }
    }
  }

  &__city {
    cursor: pointer;
    transition: color 0.24s;
    margin-bottom: 8px;
    margin-right: auto;

    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 16px;
    }
    .mask-icon {
      margin-right: 4px;
    }
    &:hover {
      color: color(primary);
    }
  }

  &__btn {
    border-bottom: 1px solid color(gray-light);
    width: 100%;
    height: 57px;
    min-height: 57px;

    &-title:deep(strong) {
      color: color(primary);
      font-weight: 400;
    }

    &-icon {
      position: absolute;
      left: -28px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__footer {
    margin-top: auto;
    margin-bottom: 16px;
  }

  &__loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 1px;
    bottom: 0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    &:deep(svg) {
      will-change: transform;

      path:not(:nth-child(3)) {
        transform-origin: center;
        animation-name: path-animation;
        animation-iteration-count: infinite;
        // animation-direction: alternate;
        animation-duration: 1s;
        transform: scale(0);
      }

      path:nth-child(1) {
        animation-delay: 0.5s;
      }
    }
  }
}
</style>
